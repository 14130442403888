* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.row {
  content: "";
  clear: both;
  display: flex;
  flex-wrap: wrap;
}

[class*="col-"] {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
  flex: 0 0 33.33333%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;

}

.text-center {
  text-align: center;
}