.tabs{
  display: flex;
  flex-direction: column;
  height: 100%;
  .labels-wrapper{
    display: flex;
    height: fit-content;
    margin: 0 16px;
    width: calc(100% - 32px);
    flex-wrap: wrap;
    background-color: $tabs_background;
    border-radius: 8.91px;
    justify-content: space-between;
    .tab-split{
      border-right: 1px solid $input_label;
      margin: 5px 0 4px 0;
      &.hide{
        visibility: hidden;
      }
    }
    .tab-label{
      padding: 0px 14px 3px 14px;
      margin: 5px 0 4px 0;
      cursor: pointer;
      line-height: 20px;
      &.active-tab-label{
        padding: 3px 14px 5px 14px;
        background: $white_color;
        margin: 2px;
        box-shadow: 0px 3px 8px rgba($black_color, 0.12), 0px 3px 1px rgba($black_color, 0.04);
        border-radius: 6.93px;
      }
    }
  }
  .tabs-content-wrapper{
    height: calc(100% - 32px);
    .tab-content{
      padding-bottom: 16px;
      display: none;
      overflow-y: scroll;
      height: 100%;
      &.active-tab-content{
        display: flex;
        flex-direction: column;
      }
    }
  }
}
