.salesreport-btn-row{
  padding: 0 20px;
}

.bouquet-btns{
  width: calc(50% - 5px);
  padding: 13px 25px 10px 25px;
  border-radius: 10px;
  border: 0;
  background-color: $inactive_btn_bg_color;
  color: $grey_title_color;
  cursor: pointer;
  margin-bottom: 12px;
  &.active-btn{
    background-color: $active_btn_bg_color;
    color: $white_color;
  }
}

.filter-btns{
  width: calc(25% - 5px);
  padding: 13px 10px 10px 10px;
  border-radius: 10px;
  border: 0;
  background-color: $inactive_btn_bg_color;
  color: $grey_title_color;
  cursor: pointer;
  margin-top: 12px;
  &.active-btn{
    background-color: $active_btn_bg_color;
    color: $white_color;
  }
}

.selected-bouquet-rep{
  margin-top: 4px !important;
  margin-bottom: 12px !important;
}

.splitter{
  box-shadow: 0px 0.5px 0px $split_color;
  height: 1px;
  margin-bottom: 4px;
}

.report-navigation{
  margin-top: 32px;
  p{
    margin: 0;
  }
  svg{
    cursor: pointer;
  }
}