.terms-conds-modal-container{
  padding: 26px 0px 90px 0px;
  height: 100%;
  .close-icon{
    position: absolute;
    top: 26.2px;
    right: 8px;
  }
  .terms-title{
    margin-top: 10px;
    margin-bottom: 29px;
  }
  .terms-conds-modal-body{
    // text-align: center;
    letter-spacing: 0.4px;
    overflow-y: scroll;
    height: calc(100% - 60px);
    padding: 0 16px;
    p.subtitle-color{
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
}

.modal.no-radius{
  position: relative;
}