.report-layout{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0000000f;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.salesreport-chart{
  position: relative;
}

.fetching-report .salesreport-header, .fetching-report .salesreport-footer{
  filter: blur(6px);
}

.salesreport-header{
  padding: 16px 10px 0 10px;
}

.salesreport-footer{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 16px;
  .left-col{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .subtitle-color{
      margin: 0;
      margin-bottom: 4px;
    }
    .title-color{
      margin: 0;
    }
  }
}