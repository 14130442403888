.flowersType {
  height: 100%;
  display: flex;
  flex-direction: column;
  .flowersType-header {
    background-color: rgba($popup_header_background, 0.94);
    width: 100%;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 0.5px 0px rgba($black_color, 0.3);
    border-radius: 10px 10px 0 0;
    .back-icon {
      position: absolute;
      left: 9px;
      top: 26px;
    }
    p{
      margin-top: 9px;
      margin-bottom: 16px;
      line-height: 28px;
    }
    .flowersType-search {
      display: flex;
      padding: 7px 8px;
      width: calc(100% - 32px);
      margin: 0 16px 10px;
      border-radius: 10px;
      background-color: rgba($search_background, 0.12);
      input {
        border: none;
        background: transparent;
        outline: none;
        padding-left: 6.5px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .flowersType-body{
    display: flex;
    flex-wrap: wrap;
    margin: 2px 11px 19px;
    overflow-y: scroll;
    height: calc(100% - 110px);
    .flowersType-item {
      margin-top: 19px;
      width: 33.33%;
      flex: 0 0 33.33333%;
      padding: 0 5px;
      align-items: center;
      // justify-content: center;
      display: flex;
      flex-direction: column;
      .flowersType-itemImg {
        width: 107px;
        height: 107px;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .flowersType-itemTitle {
        text-transform: capitalize;
        text-align: center;
        margin: 8px 0 0;
      }
    }
  }
}