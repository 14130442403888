.fixed-button {
  padding: 10px 16px 22px;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  width: -webkit-fill-available;
  box-shadow: 0px 2px 16px rgba($black_color, 0.16);
  background-color: $white_color;
  // left: 0;
  max-width: 461px;
}

.florist-btn{
  background-color: $primary_color;
  color: $white_color;
  padding: 16px; 
  border-radius: 6px;
  width: 100%;
  user-select: none;
  border: 0px;
  cursor: pointer;
  font-family: "Gilroy-medium", sans-serif;
  font-size: 16px;
  line-height: 16px;
  min-height: 48px;
  &.disabled{
    background-color: $input_label;
  }
}