.add-bouquet-card-header{
  background-image: url('../../../images/add-bouquet.png');
  height: 185px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.add-bouquet-card-footer{
  margin: 10px 16px;
  p{
    margin: 0;
  }
}