.select-design-modal {
  padding: 0 16px;
  height: 100%;
  .select-design-header {
    border-radius: 10px 10px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .close-icon {
      position: absolute;
      right: 11px;
      top: 13px;
    }
    p{
      margin: 16px 0;
    }
  }
  .select-design-item {
    padding: 23px 10px 21px;
    background-color: $qty_background;
    border-radius: 10px;
    height: calc(100% - 51px);
    overflow-y: scroll;
    p{
      margin: 0;
    }
  }
}