@font-face {
  font-family: Fedra-light;
  src: url(../fonts/Fedra/Fedra-Light.otf) format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: Fedra-regular;
  src: url(../fonts/Fedra/Fedra-Regular.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Fedra-medium;
  src: url(../fonts/Fedra/Fedra-Medium.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: Fedra-bold;
  src: url(../fonts/Fedra/Fedra-Bold.otf) format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: Fedra-heavy;
  src: url(../fonts/Fedra/Fedra-Heavy.otf) format("opentype");
  font-weight: 900;
}


.ar-app{
  direction: rtl;
  .light-txt{
    font-family:  "Fedra-light", sans-serif;
  }
  
  .regular-txt{
    font-family:  "Fedra-regular", sans-serif;
  }
  
  .medium-txt{
    font-family:  "Fedra-medium", sans-serif;
  }
  
  .bold-txt{
    font-family:  "Fedra-bold", sans-serif;
  }
  
  .heavy-txt{
    font-family:  "Fedra-heavy", sans-serif;
  }

  .design-card-body{
    .info-design-container{
      padding-right: 10px;
      padding-left: 0px;
    }
  }

  .balance-card-header{
    .available-balance{
      padding-right: 16px;
      padding-left: 0px;
      .title-color{
        font-family: "Gilroy-bold", sans-serif;
        direction: ltr;
        text-align: right;
      }
    }
  }

  .bankdetails-card-header{
    padding: 16px 3px 0px 7px;
    .add-bankdetails-note{
      padding-right: 9px;
      padding-left: 0px;
    }
  }

  .howItWorks-card{
    direction: ltr;
    // padding: 11px 11px 11px 28px;
    // .howItWorks-icon{
    //   left: 20px;
    //   right: unset;
    // }
  }

  .design-form .types-options-container .type-container{
    .flower-type-option{
      padding-left: 4.5px;
      padding-right: 0px;
    }
  }

  .input-container .custom-label{
    right: 16px;
    left: unset;
  }

  .landing-back-icon{
    transform: rotate(180deg);
    right: 15px;
    left: unset;
  }

  .top-header .back-icon{
    transform: rotate(180deg);
    right: 10px;
    left: unset;
  }

  .design-big-container .card .bin-icon{
    right: unset;
    left: 5px;
  }

  .salesreport-header{
    direction: ltr;
  }

  .report-navigation{
    svg{
      transform: rotate(180deg);
    }
  }

  .currency-txt{
    font-family: "Gilroy-bold", sans-serif
  }

  .checkbox-container{
    padding-right: 28px;
    padding-left: 0;
    .checkmark{
      right: 0;
      left: unset;
    }
  }
  
  .design-form .p-l-28{
    padding-left: unset;
    padding-right: 28px;
  }

  // toast
  .Toastify__toast{
    direction: rtl;
    .Toastify__toast-body{
      font-family: "Fedra-regular", sans-serif;
    }
  }
}