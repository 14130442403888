@import '../modals/StatusModal.scss';
@import '../modals/FlowersTypesModal.scss';
@import '../modals/FlowersQtyModal.scss';
@import '../modals/SalesReportModal.scss';
@import '../modals/SelectDesignModal.scss';
@import '../modals/TermsCondsModal.scss';

.modal-overlay.show {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($black_color, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
 

  .modal.show {
    background-color: $white_color;
    height: calc(100% - 11px);
    width: 100%;
    max-width: 461px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    border-radius: 10px 10px 0 0;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s
  }
}

.modal-overlay.hide,
.modal.hide {
  display: none;
}


/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}
