$primary_color: #006782;
$grey_title_color: #4A5153;
$grey_subtitle_color: #797979;
$white_color: #ffffff;
$black_color: #000000;
$light_black_color: #333333;
$light_grey_color: #707070;
$warning: #FFA800;
$success: #84C502;
$danger: #FE3D5E;
$info: #007bff;
$input_label: #D8D8D8;
$input_PH: #C3BEB6;
$option_color: #262626;
$option_border_color: #0C697E;
$option_bg_color: #F4FCFF;
$qty_color: #4F5659;
$premium_black: #001115;

$light_blue_background: #F2F7F9;
$light_yellow_background: #FDEED3;
$light_grey_background: #FDF2EE;
$upload_img_background: #F8F8F8;
$tabs_background: #EFEFF4;
$popup_header_background: #F9F9F9;
$search_background: #767680;
$qty_background: #F5F5F5;
$split_color: #DDDDDD;
$active_btn_bg_color: #4FB1C8;
$inactive_btn_bg_color: #F7F7F7;
$transaction_bg: #EDEDED;