.howItWorks-card{
  background-image: url('../../../images/how-it-works-bg.png');
  height: 114px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 8px;
  padding: 11px 28px 11px 11px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  .howItWorks-title{
    color: $white_color;
    margin: 0;
    margin-top: 9px;
  }
  .howItWorks-link{
    color: $white_color;
    text-decoration: underline;
    margin-top: auto;
    margin-bottom: 1px;
  }
  .howItWorks-icon{
    position: absolute;
    right: 20px;
    top: 11px;
  }
}