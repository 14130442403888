@font-face {
  font-family: Gilroy-light;
  src: url(../fonts/Gilroy/Gilroy-Light.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: Gilroy-regular;
  src: url(../fonts/Gilroy/Gilroy-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Gilroy-medium;
  src: url(../fonts/Gilroy/Gilroy-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Gilroy-bold;
  src: url(../fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: Gilroy-heavy;
  src: url(../fonts/Gilroy/Gilroy-Heavy.ttf) format("truetype");
  font-weight: 900;
}

.light-txt{
  font-family:  "Gilroy-light", sans-serif;
}

.regular-txt{
  font-family:  "Gilroy-regular", sans-serif;
}

.medium-txt{
  font-family:  "Gilroy-medium", sans-serif;
}

.bold-txt{
  font-family:  "Gilroy-bold", sans-serif;
}

.heavy-txt{
  font-family:  "Gilroy-heavy", sans-serif;
}

.sz-10{
  font-size: 10px;
}

.sz-12{
  font-size: 12px;
}

.sz-13{
  font-size: 13px;
}

.sz-14{
  font-size: 14px;
}

.sz-16{
  font-size: 16px;
}

.sz-18{
  font-size: 18px;
}

.sz-20{
  font-size: 20px;
}

.sz-24{
  font-size: 24px;
}

.sz-30{
  font-size: 30px;
}

.line-h-14{
  line-height: 14px;
}

.line-h-16{
  line-height: 16px;
}

.line-h-17{
  line-height: 17px;
}

.line-h-20{
  line-height: 20px;
}

.line-h-24{
  line-height: 24px;
}

.p-m-0{
  margin: 0;
}