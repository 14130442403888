.balance-card-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 7px 10px 7px;
  .available-balance{
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 16px;
    .title-color{
      margin: 0;
      margin-bottom: 4px;
      line-height: 30px;
      min-height: 30px;
    }
    .subtitle-color{
      margin: 0;
      line-height: 16px;
    }
  }
}

.balance-card-footer{
  padding: 16px;
  box-sizing: border-box;
  .withdraw-btn{
    background-color: $light_blue_background;
    width: 100%;
    border: 0;
    padding: 11px;
    border-radius: 6px;
    color: #0C697E;
    cursor: pointer;
  }
}

.balance-card-footer-notes{
  background-color: $light_blue_background;
  padding: 16px 20px 16px 7px;
  box-sizing: border-box;
  ul{
    padding-left: 14px;
    margin: 0;
    li{
      line-height: 19px;
    }
  }
}