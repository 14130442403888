.flowerQty {
  padding: 0 16px;
  .flowerQty__header {
    border-radius: 10px 10px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .close-icon {
      position: absolute;
      right: 11px;
      top: 13px;
    }
    p{
      margin: 16px 0;
    }
  }
  .flowerQty__item {
    padding: 23px 10px 21px;
    background-color: $qty_background;
    border-radius: 10px;
    p{
      margin: 0;
    }
    .flowerQty-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 86px;
      margin-top: 26px;
      p{
        color: $qty_color;
        padding: 0 48px;
      }
    }
  }
}

.btn-transparent {
  background-color: transparent;
  border: 1px solid $primary_color;
  color: $primary_color;
  border-radius: 6px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  svg{
    fill: $primary_color;
  }
  &:disabled {
    border-color: rgba($primary_color, 0.5);
    color: rgba($primary_color, 0.5);
    svg{
      fill: rgba($primary_color, 0.5);
    }
  }
}