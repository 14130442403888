@import './common/FixedButton.scss';
@import './common/Popup.scss';
@import './common/Tabs.scss';
@import './common/Loader.scss';
@import './common/Checkbox.scss';

html, #root, .App{
  height: 100%;
}

.App-loading{
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  height: calc(100% - 50px);
  margin: 0;
  font-family: "Gilroy-regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.top-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white_color;

  .back-icon {
    position: fixed;
    left: 10px;
    cursor: pointer;
  }

  p {
    margin: 15px 0;
    line-height: 20px;
    color: $grey_title_color;
  }
}

.big-container {
  margin-top: 50px;
  padding: 16px;
  overflow-y: scroll;
  height: 100%;
}

.mobile-container{
  margin-top: 0px;
}

.maxwidth-container{
  max-width: 461px;
  margin: 0 auto;
  position: relative;
}

.design-big-container{
  overflow-y: unset;
  padding: 16px 0;
  .maxwidth-container{
    height: 100%;
    > div:first-child{
      height: 100%;
    }
  }
}

.no-side-padding{
  padding-left: 0;
  padding-right: 0;
}

.form-container{
  padding-left: 16px;
  padding-right: 16px;
}

.card {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.card-shadow {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
}

.clickable-card{
  cursor: pointer;
}

.primary-color {
  color: $primary_color;
}

.title-color {
  color: $grey_title_color;
}

.subtitle-color {
  color: $grey_subtitle_color;
}

.title-design{
  color: $light_black_color;
}

.light-date{
  color: $light_grey_color;
}  

.under-review-design {
  color: $warning;
}

.approved-design {
  color: $success;
}

.rejected-design {
  color: $danger;
}

.premium-black{
  color: $premium_black;
}

.yellow-note{
  background-color: $light_yellow_background;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}

.light-blue-note{
  background-color: $light_blue_background;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}

.spliter{
  margin-top: 32px;
  height: 0.5px;
  margin-bottom: 36px;
  background-color: $split_color;
}

.input-container{
  position: relative;
  display: flex;
  flex-direction: column;
  .custom-textarea{
    min-height: 146px;
  }
  .custom-input{
    padding: 16px;
    margin-bottom: 22px;
    border: 1px solid $input_label;
    border-radius: 6px;
    &.input-with-label{
      padding: 24px 16px 8px 16px;
      border-color: $primary_color;
      outline: 0;
    }
    &::placeholder{
      color: $input_PH;
      font-family: "Gilroy-medium", sans-serif;
      font-size: 16px;
    }
    &:focus-visible{
      border-color: $primary_color;
      outline: 0;
    }
  }
  .custom-label{
    position: absolute;
    top: 8px;
    left: 16px; 
  }
}

.white-space{
  white-space: break-spaces;
}

.no-radius{
  border-radius: 0;
}

// toast
.Toastify__toast--success {
  background: $success !important;
}
.Toastify__toast--info {
  background: $info !important;
}
.Toastify__toast--warning {
  background: $warning !important;
}
.Toastify__toast--error {
  background: $danger !important;
}
.Toastify__toast-body{
  font-family: "Gilroy-regular", sans-serif;
}