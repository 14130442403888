.transferring-to-title{
  margin: 15px 0 10px 0;
}

.bankdetails-card-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 7px 0px 3px;
  .add-bankdetails-note{
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 9px;
    .title-color{
      margin: 0;
      line-height: 20px;
    }
    .account-name{
      margin-top: -3px;
      margin-bottom: 10px;
    }
    .bank-name{
      margin-top: 0px;
      margin-bottom: 4px;
    }
    .account-no{
      margin: 0;
    }
  }
}

.bankdetails-card-footer{
  padding: 16px;
  box-sizing: border-box;
  &.change-bankDetails-container{
    padding: 8px 10px 16px;
  }
  .add-bankdetails-btn{
    background-color: $light_blue_background;
    width: 100%;
    border: 0;
    padding: 11px;
    border-radius: 6px;
    color: #0C697E;
    cursor: pointer;
  }
  .change-bankDetails{
    text-align: right;
    margin: 0;
    cursor: pointer;
  }
}