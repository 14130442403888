.status-modal-header{
  width: 100%;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 0.5px 0px rgba($black_color, 0.3);
  .close-icon{
    margin-left: 16px;
    position: absolute;
    left: 0;
    cursor: pointer;
  }
  p{
    margin-top: 14px;
    margin-bottom: 14px;
    line-height: 16px;
  }
}

.status-modal-body{
  padding: 16px;
  overflow-y: scroll;
  height: calc(100% - 70px);
  > .card{
    .bin-icon{
      display: none!important;
    }
  }
  .status-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > p{
    white-space: pre-wrap;
    text-align: center;
    &.title-color{
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &.subtitle-color{
      margin-top: 0px;
      margin-bottom: 27px;
      padding: 0 16px;
    }
    &.transferring-to-title{
      text-align: unset;
      margin-top: 9px;
      margin-bottom: 10px;
    }
  }
}